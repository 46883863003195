import React from "react";
import { Layout, SEO } from '../../components/structure';
import { CallToAction, Content, Hero, Button, IconTile, Grid, Column, StaggeredColumn, BlogCard } from "../../components/blocks"
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import styled, { css } from "styled-components";
import { mq } from "../../styles"
import english from "../../../content/translations/en-CA/products.json"
import french from "../../../content/translations/en-CA/products.json"

const DetailTile = styled.div`
  width: 90%; 
  max-width: 970px;
  margin: auto;
  padding-top: 60px;

  @media only screen and ${mq.maxSm}{
    width: 90%;
    max-width: 400px;
  }

  .image-tile-wrapper {
    width: 100%;
    height: 28vw;
    max-height: 240px;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
    @media only screen and ${mq.maxSm}{
      max-height: 115px;
    }
  }

  .tile-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 0 40px;
    padding: 20px 50px;
    background-color: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    
    @media only screen and ${mq.maxSm}{
      flex-direction: column;
    }

    .tile-copy {
      flex-basis: 50%;

      .highlight {
        text-transform: uppercase;
        font-weight: 600;
      }
      
      h4 {
        font-size: 34px;
      }
      span {
        display: block;
        margin: 20px 0;
        font-weight: 600;
        color: #595959;
      }

      p {
        text-align: left;
        font-size: 15px;
        color: #595959;
      }
    }

    .icon-details {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px 0;
      width: 200px;

      span {
        display: block;
        color: #595959;
		    white-space: pre-wrap;
      }
      .percentage {
        font-size: 50px;
        color: #286FF7;
        font-weight: 900;
        line-height: initial;
      }
    }
  }
 
`;

const BlogCon = styled.div`
  width: 90%;
  max-width: 970px;
  margin: 60px auto 0 auto;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  border-top: 1px solid #c4c4c5;
  @media only screen and ${mq.maxMd}{
    flex-wrap: wrap;
    max-width: 780px;
  }
  @media only screen and ${mq.maxSm}{

  }
`;

const EverydayEarnBurnPage = () => {

  const lang = english;

  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-1203865779.jpg" }) {
        ...HeroImage
      }
      icon21:  file(base: { eq: "icon-21.png" }) {
        childImageSharp {
          fixed(width: 200, height: 200, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon22:  file(base: { eq: "icon-22.png" }) {
        childImageSharp {
          fixed(width: 200, height: 200, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon39:  file(base: { eq: "icon-39.png" }) {
        childImageSharp {
          fixed(width: 200, height: 200, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      iconLock: file(base: {eq:"icon-lock-03.png" }){
        childImageSharp {
          fixed(width: 200, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon41: file(base: {eq:"icon-41.png"}){
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon43: file(base: {eq:"icon-43.png"}){
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hiltonLyft: file(base: {eq: "Lyft-Hilton-Hero.jpg"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog1: file(base: {eq: "2xLoyalty_Blog.png"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog2: file(base: {eq: "APAC_Airline_Blog.png"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog3: file(base: {eq: "Emirates_Blog.png"}){
        childImageSharp {
          fluid(webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giftCards: file(base: { eq: "gift-cards.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      auction: file(base: { eq: "auctions.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
      merchant_04: file(base: { eq: "Lyft.png" }) {
        ...MerchantLogoCanvas
      }
      merchant_07: file(base: { eq: "TicketMaster.png" }) {
        ...MerchantLogoCanvas
      }
      merchant_01: file(base: { eq: "FTD_2023.png" }) {
        ...MerchantLogoCanvas
      }
      merchant_03: file(base: { eq: "HomeChef.png" }) {
        ...MerchantLogoCanvas
      }
      merchant_02: file(base: { eq: "GYG.png" }) {
        ...MerchantLogoCanvas
      }
      merchant_05: file(base: { eq: "Marathon.png" }) {
        ...MerchantLogoCanvas
      }
      merchant_06: file(base: { eq: "Rocket.png" }) {
        ...MerchantLogoCanvas
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title= { lang.ede.meta.title }
        description={ lang.ede.meta.description }
      />
      <Hero zero title={ lang.ede.hero.title } subtitle={<div><p>{ lang.ede.hero.subtitle }</p><Button type={"white"} hero destination={"/contact/schedule-a-call"} label={ lang.ede.hero.buttonText }/></div>} image={data['heroImg']} />
      <Content greyed>
        <h1 style={{ paddingTop: '50px', textAlign: 'center', margin: 'auto', maxWidth: '610px' }}>{ lang.ede.content.intro_section_one.title }</h1>
        <StaggeredColumn>
          <IconTile title={ lang.ede.content.intro_section_one.icon_one.title } icon={data['icon21']} border={true} imgConStyle={{height: '120px'}} imgStyle={{objectFit: 'contain'}}>
            <p>{ lang.ede.content.intro_section_one.icon_one.copy }</p>
          </IconTile>
          <IconTile title={ lang.ede.content.intro_section_one.icon_two.title } icon={data['icon39']} border={true} imgConStyle={{height: '120px', objectFit: 'contain'}} imgStyle={{objectFit: 'contain'}}>
            <p>{ lang.ede.content.intro_section_one.icon_two.copy }</p>
          </IconTile>
          <IconTile title={ lang.ede.content.intro_section_one.icon_three.title } icon={data['icon22']} border={true} imgConStyle={{height: '120px', objectFit: 'contain'}} imgStyle={{objectFit: 'contain'}}>
            <p>{ lang.ede.content.intro_section_one.icon_three.copy }</p>
          </IconTile>
          <IconTile title={ lang.ede.content.intro_section_one.icon_four.title } icon={data['iconLock']} border={true} imgConStyle={{height: '120px', objectFit: 'contain'}} imgStyle={{objectFit: 'contain'}}>
            <p>{ lang.ede.content.intro_section_one.icon_four.copy }</p>
          </IconTile>
        </StaggeredColumn>
      </Content>

      <Content>
        <h1 style={{ paddingTop: '30px', textAlign: 'center', margin: 'auto', maxWidth: '610px' }}>{ lang.ede.content.merchant_partners_title }</h1>
        <div className={"partner-icon-list"}>
          <GatsbyImage fixed={data['merchant_04'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['merchant_07'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['merchant_01'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['merchant_03'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['merchant_05'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['merchant_06'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
        </div>
      </Content>

      <Content greyed>

        <DetailTile>
          <div className={"image-tile-wrapper"}>
            <GatsbyImage fixed={data['hiltonLyft'].childImageSharp.fluid} imgStyle={{ objectFit: 'cover',objectPosition: 'center', borderTopRightRadius: '25px', borderTopLeftRadius: '25px' }} style={{width: '100%', height: '100%'}} alt={'Icon 1'} />
          </div>
          <div className={"tile-content"}>
            <div className={"tile-copy"}>
              <span className={"highlight"}>
                { lang.ede.content.success_story.highlight }
              </span>
              <h4>{ lang.ede.content.success_story.headline }</h4>
              <p>
                { lang.ede.content.success_story.copy }
              </p>
            </div>
            <div className={"icon-details desktop-vis"}>
              <GatsbyImage fixed={data['icon41'].childImageSharp.fixed} imgStyle={{ objectFit: 'contain',objectPosition: 'bottom center', maxWidth: '100px' }} style={{height: '100px'}} alt={'Icon 1'} />
              <p>
                <span className={"percentage"}>{ lang.ede.content.success_story.stats_block_one.stat }</span>
                <span>{ lang.ede.content.success_story.stats_block_one.copy }</span>
              </p>
            </div>
            <div className={"icon-details desktop-vis"}>
              <GatsbyImage fixed={data['icon43'].childImageSharp.fixed} imgStyle={{ objectFit: 'contain',objectPosition: 'bottom center', maxWidth: '100px' }} style={{height: '100px'}} alt={'Icon 1'} />

              <p>
                <span className={"percentage"}>{ lang.ede.content.success_story.stats_block_two.stat }</span>
                <span>{ lang.ede.content.success_story.stats_block_two.copy }</span>
              </p>
            </div>
          </div>
        </DetailTile>

        <div className={"mob-icon-details mobile-vis"}>
          <GatsbyImage fixed={data['icon41'].childImageSharp.fixed} imgStyle={{ objectFit: 'contain',objectPosition: 'bottom center', maxWidth: '100px' }} style={{height: '100px'}} alt={'Icon 1'} />
          <p>
            <span className={"percentage"}>{ lang.ede.content.success_story.stats_block_one.stat }</span>
            <span>{ lang.ede.content.success_story.stats_block_one.copy }</span>
          </p>
        </div>
        <div className={"mob-icon-details mobile-vis"}>
          <GatsbyImage fixed={data['icon43'].childImageSharp.fixed} imgStyle={{ objectFit: 'contain',objectPosition: 'bottom center', maxWidth: '100px' }} style={{height: '100px'}} alt={'Icon 1'} />

          <p>
            <span className={"percentage"}>{ lang.ede.content.success_story.stats_block_two.stat }</span>
            <span>{ lang.ede.content.success_story.stats_block_two.copy }</span>
          </p>
        </div>

        <BlogCon>
          <BlogCard 
            image={data["blog1"]} 
            toURL={ lang.ede.content.blog_content.blog_one.linkURL } 
            copy={ lang.ede.content.blog_content.blog_one.copy } 
            imgStyle={{height: "150%"}}
          />
          <BlogCard 
            image={data["blog2"]} 
            toURL={ lang.ede.content.blog_content.blog_two.linkURL }
            copy={ lang.ede.content.blog_content.blog_two.copy } 
            imgStyle={{height: "100%", objectPosition: "left center"}}
          />
          <BlogCard 
            image={data["blog3"]} 
            toURL={ lang.ede.content.blog_content.blog_three.linkURL }
            copy={ lang.ede.content.blog_content.blog_three.copy } 
            imgStyle={{height: "100%", objectPosition: "right center"}}
          />
        </BlogCon>


      </Content>

      <CallToAction title={ lang.ede.call_to_action.title }>
        <p>{ lang.ede.call_to_action.copy }</p>
        <Button type={'white'} destination={'/contact/schedule-a-call'} label={ lang.ede.call_to_action.buttonText } />
      </CallToAction>
    </Layout>
  );
}

export const merchquery = graphql`
fragment MerchantLogoCanvas on File {
  childImageSharp {
    fluid(maxWidth: 150, webpQuality: 100) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
`;

export default EverydayEarnBurnPage;
